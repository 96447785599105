/// <reference path="common/app.js"/>
"use strict";

//require("angular");

//require("./common/app.js");

(function (angular) {
    var app = angular.module("tq", ["tq.common"]);
    // Array.map polyfill from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/map

    // Production steps of ECMA-262, Edition 5, 15.4.4.19
    // Reference: http://es5.github.io/#x15.4.4.19
    if (!Array.prototype.map) {

        Array.prototype.map = function (callback, thisArg) {

            var T, A, k;

            if (this == null) {
                throw new TypeError(" this is null or not defined");
            }

            // 1. Let O be the result of calling ToObject passing the |this|
            //    value as the argument.
            var O = Object(this);

            // 2. Let lenValue be the result of calling the Get internal
            //    method of O with the argument "length".
            // 3. Let len be ToUint32(lenValue).
            var len = O.length >>> 0;

            // 4. If IsCallable(callback) is false, throw a TypeError exception.
            // See: http://es5.github.com/#x9.11
            if (typeof callback !== "function") {
                throw new TypeError(callback + " is not a function");
            }

            // 5. If thisArg was supplied, let T be thisArg; else let T be undefined.
            if (arguments.length > 1) {
                T = thisArg;
            }

            // 6. Let A be a new array created as if by the expression new Array(len)
            //    where Array is the standard built-in constructor with that name and
            //    len is the value of len.
            A = new Array(len);

            // 7. Let k be 0
            k = 0;

            // 8. Repeat, while k < len
            while (k < len) {

                var kValue, mappedValue;

                // a. Let Pk be ToString(k).
                //   This is implicit for LHS operands of the in operator
                // b. Let kPresent be the result of calling the HasProperty internal
                //    method of O with argument Pk.
                //   This step can be combined with c
                // c. If kPresent is true, then
                if (k in O) {

                    // i. Let kValue be the result of calling the Get internal
                    //    method of O with argument Pk.
                    kValue = O[k];

                    // ii. Let mappedValue be the result of calling the Call internal
                    //     method of callback with T as the this value and argument
                    //     list containing kValue, k, and O.
                    mappedValue = callback.call(T, kValue, k, O);

                    // iii. Call the DefineOwnProperty internal method of A with arguments
                    // Pk, Property Descriptor
                    // { Value: mappedValue,
                    //   Writable: true,
                    //   Enumerable: true,
                    //   Configurable: true },
                    // and false.

                    // In browsers that support Object.defineProperty, use the following:
                    // Object.defineProperty(A, k, {
                    //   value: mappedValue,
                    //   writable: true,
                    //   enumerable: true,
                    //   configurable: true
                    // });

                    // For best browser support, use the following:
                    A[k] = mappedValue;
                }
                // d. Increase k by 1.
                k++;
            }

            // 9. return A
            return A;
        };
    }

    // Date.toISOString polyfill from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toISOString
    // This should only not exist in IE8.
    if (!Date.prototype.toISOString) {
        (function () {

            function pad(number) {
                if (number < 10) {
                    return "0" + number;
                }
                return number;
            }

            Date.prototype.toISOString = function () {
                return this.getUTCFullYear() +
                  "-" + pad(this.getUTCMonth() + 1) +
                  "-" + pad(this.getUTCDate()) +
                  "T" + pad(this.getUTCHours()) +
                  ":" + pad(this.getUTCMinutes()) +
                  ":" + pad(this.getUTCSeconds()) +
                  "." + (this.getUTCMilliseconds() / 1000).toFixed(3).slice(2, 5) +
                  "Z";
            };

        }());

        // Override Date.parse in IE8 too.
        // Polyfill from https://gist.github.com/farmdawgnation/8887962 (slightly modified to not require Date.js)
        Date._parse = Date.parse;
        Date.parse = function (dateString) {
            var date, time, r = null;
            if (!dateString)
                return null;

            if (dateString instanceof Date)
                return dateString;

            try {
                date = new Date(Date._parse(dateString));
                time = date.getTime();

                // The following will be FALSE if time is NaN which happens if date is an Invalid Date
                // (yes, invalid dates are still date objects. Go figure.)
                if (time === time)
                    return date;
            } catch (e) {
                // Don't care...
            }

            // The Grammar parser and date constructor failed. That means we were passed an
            // ISO 8601 string that doesn't compute using the date constructor likely because
            // we're in freaking IE 8. Our last ditch effort is to try and parse the 8601 date
            // manually.
            var parts = dateString.match(/\d+/g);
            var isoTime = Date.UTC(parts[0], parts[1] - 1, parts[2], parts[3], parts[4], parts[5]);
            var isoDate = new Date(isoTime);

            return isoDate;
        };
    }

    return app;
})(angular);
